import React, { useEffect, useState } from "react";
import { Form, Radio, Select, Space } from "antd";
import Condition from "./conditions";
import { filterInputs } from "../../conditionalLogic";
import { useIntl } from "react-intl";

const ConditionLogic = (props) => {
  const [conditional, setConditional] = useState(
    props.value ? props.value.status : false
  );
  const [conditions, setConditions] = useState([]) as any;
  const [value, setValue] = useState(
    props.value
      ? props.value
      : {
          status: false,
          action: "",
          operator: "",
          conditions: [{ filedTocompare: "", ifCondition: "", value: "" }],
        }
  );
const intl =useIntl()
  const type = "RadioButtons";
  const options = [
    { label:intl.formatMessage({id: "Yes"}), value: true },
    { label: intl.formatMessage({id: "No"}), value: false },
  ];
  useEffect(() => {
    conditional
      ? props.form.setFieldsValue({ conditionLogic: value })
      : props.form.setFieldsValue({ conditionLogic: value });
  });
  useEffect(() => {
    setValue({ ...value, conditions: conditions });
  }, [conditions, setConditions]);

  return (
    <>
      <Form.Item shouldUpdate name="conditionLogic" label={intl.formatMessage({id:"conditionLogic"})}>
        {" "}
        <Radio.Group
          value={value.status}
          onChange={(e) => {
            setConditional(e.target.value);
            setValue({ ...value, status: e.target.value });
          }}
          options={options}
        />
        {conditional && (
          <>
            <Space align="baseline">
              <Select
                value={Array.isArray(value.action) ? "S" : value.action}
                placeholder="action"
                style={{ borderRadius: "10px", height: "auto" }}
                dropdownStyle={{ borderRadius: "10px" }}
                className="border rounded-lg h-full"
                options={[
                  { label: intl.formatMessage({id:"show"}), value: "S" },
                  { label:intl.formatMessage({id:"hide"}), value: "H" },
                  { label: intl.formatMessage({id:"disable"}), value: "D" },
                ]}
                onChange={(e) => {
                  setValue({ ...value, action: e });
                }}
              />
              <span>{ intl.formatMessage({id:"when"})} </span>
              <Select
                value={Array.isArray(value.operator) ? "all" : value.operator}
                style={{ borderRadius: "10px", height: "auto" }}
                dropdownStyle={{ borderRadius: "10px" }}
                className="border rounded-lg h-full"
                placeholder="operator"
                options={[
                  { label:intl.formatMessage({id:"all"}), value: "all" },
                  { label:intl.formatMessage({id:"one"}), value: "one" },
                ]}
                onChange={(e) => {
                  setValue({ ...value, operator: e });
                }}
              />{" "}
                <p> {intl.formatMessage({id:"conditionsAreValid"})}</p>
            </Space>
            <Condition
              elements={filterInputs(
                props.elements,
                props.selectedElm,
                value.operator,
                value.conditions
              )}
              intl={intl}
              setValue={setConditions}
              value={value}
            ></Condition>
          </>
        )}
      </Form.Item>{" "}
    </>
  );
};

export default ConditionLogic;
