import React, { useEffect, useState } from "react";
import {
  Divider,
  Form,
  InputNumber,
  Checkbox,
  Radio,
  Input as Inputa,
  Select,
  TimePicker,
  Slider,
  Rate,
  Upload,
  Button,
  DatePicker,
} from "antd";
import frFR from "antd/es/date-picker/locale/fr_FR";
import ReactHtmlParser from "react-html-parser";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-datepicker/dist/react-datepicker.css";
import Input from "../../Input";
import FComp from "./useForm";
import Signature from "./signature";
import { FullNameInput } from "./customInputs/lastFirstNameInput";
import { FullAdressInput } from "./customInputs/adresse";
interface Props {
  element: any;
  readOnly: any;
  form?: any;
  selectedElm?: any;
}

const FormElement = ({ element, readOnly, form, ...props }) => {
  const { dropEffect, type, id, label, field_name, rules, ...data } = element;
  const [selectedElm, setSelectedElm] = useState(id ? id : null);
  useEffect(() => {
    setSelectedElm(props.selectedElm);
  }, [props.selectedElm]);
  const wrapFormItem = (children) => {
    return (
      <Form.Item
        label={label}
        labelCol={{ className: "mx-2" }}
        tooltip
        key={field_name}
        name={field_name}
        rules={readOnly ? [] : rules}
        className=" flex grid-cols-2 "
      >
        {children}
      </Form.Item>
    );
  };

  const renderElement = () => {
    switch (type) {
      case "Header":
        let htmlData: String = data.content;
        htmlData = htmlData.replaceAll("&lt;", "<");
        htmlData = htmlData.replaceAll("&gt;", ">");
        return <h1>{ReactHtmlParser(htmlData)}</h1>;

      case "Paragraph":
        let htmlDataParagraph: String = data.content;
        htmlDataParagraph = htmlDataParagraph.replaceAll("&lt;", "<");
        htmlDataParagraph = htmlDataParagraph.replaceAll("&gt;", ">");
        return <p>{ReactHtmlParser(htmlDataParagraph)}</p>;
      case "LineBreak": {
        const { content, ...props } = data;
        let htmlDataLineBreak: String = content;
        htmlDataLineBreak = htmlDataLineBreak.replaceAll("&lt;", "<");
        htmlDataLineBreak = htmlDataLineBreak.replaceAll("&gt;", ">");
        return (
          <Divider {...props}>{ReactHtmlParser(htmlDataLineBreak)}</Divider>
        );
      }
      case "Image": {
        if (data.src === "")
          data.src =
            "https://via.placeholder.com/728x90.png?text=Place+KatomiCore+Image+Here";
        return (
          <div className="m-4" style={{ textAlign: "center" }}>
            <img src={data.src} alt={data.alt} />
          </div>
        );
      }
      case "TextInput":
        return wrapFormItem(
          <Input
            className="border rounded-lg h-10"
            {...data}
            disabled={readOnly ? true : false}
          />
        );
      case "Email":
        return wrapFormItem(
          <Input
            className="border rounded-lg h-10"
            {...data}
            disabled={readOnly ? true : false}
          />
        );
      case "fullName":
        return wrapFormItem(
          <FullNameInput
            className="border rounded-lg h-10"
            element={element}
            {...data}
            readOnly={readOnly ? true : false}
          />
        );
      case "fullAdress":
        return wrapFormItem(
          <FullAdressInput
            className="border rounded-lg h-10"
            element={element}
            {...data}
            readOnly={readOnly ? true : false}
          />
        );

      case "NumberInput":
        return wrapFormItem(
          <InputNumber
            className="border rounded-lg h-10"
            {...data}
            disabled={readOnly ? true : false}
          />
        );
      case "Dropdown":
        return wrapFormItem(
          <Select
            style={{ borderRadius: "10px", height: "50px" }}
            dropdownStyle={{ borderRadius: "10px" }}
            className="border  rounded-lg h-full"
            disabled={readOnly ? true : false}
            {...data}
          />
        );
      case "Tags":
        return wrapFormItem(
          <Select
            style={{ borderRadius: "10px", height: "50px" }}
            dropdownStyle={{ borderRadius: "10px" }}
            className="border rounded-lg h-full"
            disabled={readOnly ? true : false}
            {...data}
            mode="tags"
          />
        );
      case "Checkboxes":
        return wrapFormItem(
          <Checkbox.Group disabled={readOnly ? true : false} {...data} />
        );
      case "RadioButtons":
        return wrapFormItem(
          <Radio.Group disabled={readOnly ? true : false} {...data} />
        );
      case "DatePicker":
        //error
        return wrapFormItem(
          <DatePicker
            className="rounded-xl"
            locale={frFR}
            scrollableYearDropdown
            disabled={readOnly ? true : false}
            {...data}
          />
        );

      case "TimePicker":
        return wrapFormItem(
          <TimePicker disabled={readOnly ? true : false} {...data} />
        );
      case "TextArea":
        return wrapFormItem(
          <Inputa.TextArea
            className="border rounded-lg h-10"
            disabled={readOnly ? true : false}
            {...data}
          />
        );
      case "Editor":
        console.log(data);
        return wrapFormItem(<FComp el={selectedElm} {...data} />);
      case "Range":
        const marks = { [data.min]: data.minLabel, [data.max]: data.maxLabel };
        return wrapFormItem(
          <Slider disabled={readOnly ? true : false} {...data} marks={marks} />
        );
      case "Rating":
        return wrapFormItem(
          <Rate disabled={readOnly ? true : false} {...data} />
        );
      case "Signature":
        return wrapFormItem(
          <Signature disabled={readOnly ? true : false} {...data} />
        );
      case "File":
        return wrapFormItem(
          <Upload {...data}>
            <Button disabled={readOnly ? true : false}>
              <i className="fas fa-upload"></i> Upload
            </Button>
          </Upload>
        );
      case "Photo":
        return wrapFormItem(
          <Upload
            disabled={readOnly ? true : false}
            {...data}
            listType="picture-card"
          >
            <div>
              <div style={{ marginTop: 8 }}>
                <i className="far fa-images"></i> &nbsp;Upload
              </div>
            </div>
          </Upload>
        );

      default:
        return <div>Not yet ⌛⌛....</div>;
    }
  };
  return renderElement();
};

export default FormElement;
