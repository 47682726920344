import { FormRenderer,DisplayForm } from "../../components/FormBuilderCore/index";
import React, { useState, useEffect } from "react";
import frMsg from "../../components/FormBuilderCore/language-provider/locales/fr-fr.json";
import enMsg from "../../components/FormBuilderCore/language-provider/locales/en-us.json";
import { IntlProvider } from "react-intl";
import { Formik, useFormik } from "formik";
import { Form } from "antd";

type Props = any;
const DisplayFormPage: React.FC<Props> = ({ props }) => {
  const [lang, setLang] = useState("fr");
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState([]) as any[];
  const [showPreview, setShowPreview] = useState(false);
  const onFormSubmit = (values) => {
   
    setShowPreview(true);
    console.log(values);
  };
  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
     
    },
  });
  useEffect(()=>{
    console.log("page==>",formValue)
  },[formValue])
  const onChange = (values) => {
   setFormValue(values)
  }
  return (
    <IntlProvider
      locale={"fr"}
      defaultLocale="en"
      messages={lang === "fr" ? frMsg : enMsg}
    >
 <DisplayForm onChange={onChange} onSubmit={onFormSubmit}/>
      
    </IntlProvider>
  );
};
export default DisplayFormPage;
