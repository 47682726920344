import React, { useEffect, useState } from "react";
import { FormBuilder } from "../../components/FormBuilderCore/index";
import { IntlProvider } from "react-intl";
import frMsg from "../../components/FormBuilderCore/language-provider/locales/fr-fr.json";
import enMsg from "../../components/FormBuilderCore/language-provider/locales/en-us.json";
import { Select } from "antd";
import Spinner from "../../components/Spinner";

function Dragable() {
  const [lang, setLang] = useState("fr");
  const [intialForm, setIntialForm] = useState() as any;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(intialForm);
  }, []);
  return loading ? (
    <Spinner />
  ) : (
    <div className="App">
      <IntlProvider
        locale={"fr"}
        defaultLocale="en"
        messages={lang === "fr" ? frMsg : enMsg}
      >
        <Select
          defaultValue={"fr"}
          options={[
            { label: "Français", value: "fr" },
            { label: "Anglais", value: "en" },
          ]}
          disabled
          onChange={(e) => {
            setLang(e);
          }}
        />
        <FormBuilder
          fields={intialForm ? intialForm.sections[0].task_data : []}
          formSetup={
            intialForm
              ? intialForm
              : {
                  sections: [
                    { task_data: [], stepNbr: 0, sectionName: "Section 1" },
                  ],
                  name: "",
                  description: "",
                }
          }
          onUpdate={(e) => console.log(e)}
          fieldProps={[
            {
              key: "File",
              fieldProps: {
                action: "APIs Url",
                headers: {
                  authorization: "authorization-text",
                },
              },
            },
            {
              key: "Photo",
              fieldProps: {
                action: "APIs Url",
                headers: {
                  authorization: "authorization-text",
                },
              },
            },
          ]}
          formProps={{
            labelAlign: "left",
            colon: true,
            requiredMark: true,
            labelCol: {
              span: 3,
            },
          }}
          showPreviewTab={true}

          // toolbarItems={items}
        />
      </IntlProvider>
    </div>
  );
}
export default Dragable;
