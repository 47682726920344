import React, { useEffect, useState } from "react";
import { Button, Form, Input, InputNumber, Select, Space } from "antd";
import { ReactComponent as Different } from "./assets/different.svg";
import { ReactComponent as Equal } from "./assets/equal.svg";
const Condition = (props) => {
  const [update, setUpdate] = useState(false);
  const [form] = Form.useForm();
  const [inputType, setInputType] = useState("");
  useEffect(() => {
    props.setValue(form.getFieldValue("conditions"));
  }, [setUpdate, update]);
  const options = props.elements.map((elem, i) => {
    if (
      props.value.operator === "all" &&
      props.value.conditions.find((e) => e.filedTocompare === elem.field_name)
    ) {
      return {
        label: `${elem.label + "-" + i}`,
        value: elem.field_name,
        disabled: true,
      };
    } else {
      return { label: `${elem.label + "-" + i}`, value: elem.field_name };
    }
  });

  const verifOption = () => {
    let i = 0;
    options.map((opt) => {
      if (opt.disabled) {
        i++;
      }
    });
    if (
      i == options.length ||
      (props.value.operator === "all" &&
        form.getFieldValue("conditions").length >= options.length)
    ) {
      return true;
    } else return false;
  };
  const typeInput = (fieldName) => {
    let el: any;
    props.elements.map((e) => {
      if (fieldName === e.field_name) {
        el = e;
      }
    });
    if (el) {
      return el.type === "NumberInput" ? "num" : "texte";
    } else return "";
  };

  return (
    <Form
      form={form}
      labelAlign="left"
      colon
      requiredMark
      layout="vertical"
      labelCol={{ span: 8 }}
    >
      <Form.List name="conditions" initialValue={props.value.conditions}>
        {(fields, { add, remove }) => (
          <Form.Item>
            {fields.map((field) => (
              <Space key={field.key} align="baseline">
                {props.intl.formatMessage({ id: "if" })}
                <Form.Item {...field} name={[field.name, "filedTocompare"]}>
                  <Select
                    notFoundContent="No field Items"
                    onChange={(e) => {
                      setUpdate(!update);
                      setInputType(typeInput(e));
                    }}
                    options={options}
                    className="rounded-lg"
                    placeholder={props.intl.formatMessage({
                      id: "fieldToCompare",
                    })}
                  />
                </Form.Item>
                <Form.Item {...field} name={[field.name, "ifCondition"]}>
                  <Select
                    options={[
                      {
                        label: props.intl.formatMessage({ id: "equal" }),
                        value: "equal",
                        icon: <Equal />,
                      },
                      {
                        label: props.intl.formatMessage({ id: "notEqual" }),
                        value: "not_equal",
                        icon: <Different />,
                      },
                    ]}
                    onChange={() => setUpdate(!update)}
                    placeholder="Condition"
                    className="rounded-lg"
                  />
                </Form.Item>
                {props.intl.formatMessage({ id: "to" })}
                <Form.Item {...field} name={[field.name, "value"]}>
                  {inputType === "num" ? (
                    <InputNumber
                      placeholder="Value"
                      className="rounded-lg"
                      onChange={() => setUpdate(!update)}
                    />
                  ) : (
                    <Input
                      placeholder="Value"
                      className="rounded-lg"
                      onChange={() => setUpdate(!update)}
                    />
                  )}
                </Form.Item>
                <i
                  className="fa fa-minus-circle"
                  onClick={() => {
                    remove(field.name);
                    setUpdate(!update);
                  }}
                />
              </Space>
            ))}

            <Form.Item>
              <Button
                type="primary"
                onClick={() => {
                  add();
                }}
                disabled={options.length < 1 || verifOption()}
                block
                color=""
                className="rounded-lg bg-blue-600"
              >
                {props.intl.formatMessage({ id: "AddCondition" })}
              </Button>
            </Form.Item>
          </Form.Item>
        )}
      </Form.List>
    </Form>
  );
};
export default Condition;
