import React, { useState, useEffect } from "react";
import staticFormData from "../../../config/constants/DumbFormData.json";
import frMsg from "../language-provider/locales/fr-fr.json";
import enMsg from "../language-provider/locales/en-us.json";

import { IntlProvider } from "react-intl";
import { Formik, useFormik } from "formik";

import { Form, Modal } from "antd";
import { Console } from "console";
import FormRenderer from "../form-renderer";
import Button from "../../ButtonUI";
import FormViewer from "../form-viewer";
type Props = any;
const DisplayForm: React.FC<Props> = ({ onChange,onSubmit,...props }) => {
  const [lang, setLang] = useState("fr");
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState([]) as any[];
  const [showPreview, setShowPreview] = useState(false);
  const onFormSubmit = (values) => {
    setFormValue(values);
    setShowPreview(true);
    onSubmit(values)

    console.log(values);
  };

  useEffect(() => {
    onChange(formValue);
  }, [formValue]);
  return (
    <IntlProvider
      locale={"fr"}
      defaultLocale="en"
      messages={lang === "fr" ? frMsg : enMsg}
    >
      <Form
        onValuesChange={(e) => {
          setFormValue(form.getFieldsValue());
        }}
        form={form}
        onFinish={onFormSubmit}
      >
        <FormRenderer
          elements={staticFormData.sections[1].task_data}
          form={form}
          values={formValue}
        />{" "}
        <Form.Item>
          <Button
            status="primary"
            className="float-right flexCenter mb-5"
            width="200px"
            height="50px"
            type="submit"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
      <Modal
        title="View"
        footer={null}
        width={"90%"}
        visible={showPreview}
        onCancel={(e) => setShowPreview(false)}
        style={{ width: "100%", resize: "unset" }}
      >
        <FormViewer
          elements={staticFormData.sections[1].task_data}
          values={formValue}
          descriptionProps={undefined}
          descriptionItemProps={undefined}
        ></FormViewer>
      </Modal>
    </IntlProvider>
  );
};
export default DisplayForm;
