import { Input, Col, Row, InputNumber, Select } from "antd";
import { useEffect, useState } from "react";
import countrysData from "../../../../helper/WorldNameLists.json";
interface fullAdresse {
  streetAddress?: String;
  city?: String;
  zipCode?: Number;
  country?: String;
}
export const FullAdressInput = (props: any) => {
  console.log("papa!", props);
  const { dropEffect, type, id, label, field_name, rules, ...data } =
    props.element;
  const [fullAdresse, setFullAdress] = useState<fullAdresse>();
  useEffect(() => {
    props.onChange(fullAdresse);
  }, [fullAdresse]);

  return (
    <>
      <Row className="flex gap-2 ">
        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
          <Input
            placeholder="Adresse postale"
            className="border rounded-lg h-10 w-full   "
            name="address"
            key="address"
            onChange={(e) => {
              setFullAdress((current) => {
                return { ...current, streetAddress: e.target.value };
              });
            }}
            disabled={props.readOnly ? true : false}
          />
        </Col>
        <Col xs={4} sm={6} md={8} lg={10} xl={12}>
          <InputNumber
            placeholder="Code postal / Zip"
            className="border rounded-lg h-10 w-full  "
            name="codePostal"
            key="codePostal"
            disabled={props.readOnly ? true : false}
            onChange={(e) => {
              setFullAdress((current) => {
                return { ...current, zipCode: parseInt(e.toString()) };
              });
            }}
          />
        </Col>
      </Row>
      <Row className="flex gap-2 my-2 ">
        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
          <Input
            placeholder="Ville"
            className="border rounded-lg h-10  "
            name="ville"
            key="ville"
            disabled={props.readOnly ? true : false}
            onChange={(e) => {
              setFullAdress((current) => {
                return { ...current, city: e.target.value };
              });
            }}
          />
        </Col>
        <Col xs={4} sm={4} md={8} lg={10} xl={12}>
          <Select
            placeholder="Pays"
            options={countrysData}
            className="border rounded-lg h-10"
            key="country"
            onChange={(e) => {
              console.log("event", e);

              setFullAdress((current) => {
                return { ...current, country: e };
              });
            }}
            disabled={props.readOnly ? true : false}
          />
        </Col>
      </Row>
    </>
  );
};
