/**
 * <Toolbar />
 */

import React, { useEffect, useState } from "react";
import _ from "./toolbar-draggable-item";
import FormItems from "./form-items";
import { useIntl } from "react-intl";
import { Collapse } from "antd";


function buildItems(items: any, fieldProps: any[] = [], defaultItems: any[]) {
  let resultItems: any[] = [];

  if (!items) resultItems = defaultItems;

  resultItems = resultItems.map((field: any) => {
    let found = fieldProps.find(
      (overrideField) => field.key === overrideField.key
    );
    if (found) field.fieldProps = { ...field.fieldProps, ...found.fieldProps };
    return field;
  });

  return resultItems;
}
//TODO: Fields still need to implement

const Toolbar = ({ fields, fieldProps, ...props }) => {
  const [items, setItems] = useState([]) as any[];
  const intl = useIntl();
  const onChange = (key: string | string[]) => {
    console.log(key);
  };
  useEffect(() => {
    setItems(buildItems(fields, fieldProps, FormItems(intl)));
  }, [fields, fieldProps]);

  const customInputs = [
    {
      key: "Email",
      name: "Email",
      custom: true,
      icon: "fas fa-at",
      fieldProps: {
        field_name: "email_",
        label: "Email",
        placeHolder: "jhon@doe.com",

        rules: [
          {
            required: false,
            message: intl.formatMessage({ id: "validation-msg-Req" }),
          },
          {
            type: "email",
            message: "email non valide",
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [{ filedTocompare: "", ifCondition: "", value: "" }],
        },
      },
    },
    {
      key: "fullName",
      custom: true,
      name: "Nom & Prénom",
      icon: "fas fa-user-tie",
      fieldProps: {
        field_name: "text_input_",
        label: "Nom & Prénom",
        placeHolder: "",

        rules: [
          {
            required: false,
            message: intl.formatMessage({ id: "validation-msg-Req" }),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [{ filedTocompare: "", ifCondition: "", value: "" }],
        },
      },
    },
    {
      key: "fullAdress",
      custom: true,
      name: "Adresse complète",
      icon: "fas fa-map-marked",
      fieldProps: {
        field_name: "text_input_",
        label: "Adresse complète",
        placeHolder: "",

        rules: [
          {
            required: false,
            message: intl.formatMessage({ id: "validation-msg-Req" }),
          },
        ],
        conditionLogic: {
          status: false,
          action: "S",
          operator: "all",
          conditions: [{ filedTocompare: "", ifCondition: "", value: "" }],
        },
      },
    },
  ];
  const ToolbarItem: any = _;
  const { Panel } = Collapse;
  return (
    <div className="rounded-lg m-2">
      <Collapse
        className="rounded-lg"
        defaultActiveKey={["1"]}
        onChange={onChange}
      >
        <Panel header="Entrées personnalisées" key="1">
          {customInputs.map((item) => (
            <ToolbarItem data={item} key={item.key} />
          ))}
        </Panel>
        <Panel header="Entrées classiques" key="2">
          {items.map((item) => (
            <ToolbarItem data={item} key={item.key} />
          ))}
        </Panel>
      </Collapse>
    </div>
  );
};

export default Toolbar;
