import { ReactComponent as Plus } from "../../assets/illusts/plus.svg";
import { ReactComponent as OneForm } from "../../assets/illusts/oneForm.svg";
import { useState } from "react";
import Modal from "../../components/ModalUI";
import CreationSteps from "../CreationSteps";
import history from "../../history";
import { useAtom } from "jotai";
import { User } from "../../types/user";
import { userAtom } from "../../state/auth";
import Spinner from "../../components/Spinner";
type Props = any;
const Forms: React.FC<Props> = ({ props }) => {
  const [creationModelActive, setCreationModelActive] = useState(false);
  const [listSurveyModal, setListSurveyModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [surveyList, setSurveyList] = useState([] as any[]);

  return (
    <>
      <h1 className=" flex items-center justify-center mt-4 ">
        Créer un formulaire
      </h1>
      <div className=" flex flex-wrap mx-16 items-center justify-center my-24 ">
        <div className="w-full sm:w-1/2 md:w-1/2 xl:w-1/3 p-4">
          <a
            className="c-card block bg-white shadow-md hover:shadow-2xl rounded-2xl overflow-hidden cursor-pointer"
            onClick={() => setCreationModelActive(true)}
          >
            <div className="relative bg-blue-100    overflow-hidden flexCenter">
              <Plus className="my-12 " />{" "}
            </div>
            <div className="p-4">
              <h2 className="mt-2 mb-2  font-bold">
                Commencer à partir de zéro
              </h2>
              <p className="text-md">
                Une template vierge est tout ce dont vous avez besoin .
              </p>
            </div>
          </a>
        </div>

        <div className="w-full sm:w-1/2 md:w-1/2 xl:w-1/3 p-4">
          <a
            onClick={() => setListSurveyModal(true)}
            className="c-card block bg-white shadow-md hover:shadow-2xl rounded-2xl overflow-hidden cursor-pointer"
          >
            <div className="relative  bg-blue-100  overflow-hidden flexCenter">
              <OneForm className="mt-2 " />{" "}
            </div>
            <div className="p-4">
              <h2 className="mt-2 mb-2  font-bold">
                Commencez à partir d'un modèle
              </h2>
              <p className="text-md">
                Choisissez parmi vos formulaires préenregistrés .
              </p>
            </div>
          </a>
        </div>
      </div>
      <div>
        <Modal
          showModal={creationModelActive}
          slidemodal={false}
          closeModal={() => {
            // When close modal reset component
            setCreationModelActive(false);
          }}
        >
          {/* <div> */}
          <CreationSteps />

          {/* </div> */}
        </Modal>
        <Modal
          showModal={listSurveyModal}
          slidemodal={false}
          closeModal={() => {
            // When close modal reset component
            setListSurveyModal(false);
          }}
        >
          {loader ? (
            <Spinner />
          ) : (
            <div className="p-16">
              {surveyList.map((e: any, i) => {
                return (
                  <div
                    className={`card-patient justify-between bg-white  ${""}`}
                    key={i}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      console.log(e);
                      if (e._id) {
                        history.push(`creation/${e._id}`);
                      }
                    }}
                  >
                    <div className="flex patient-info">
                      <div
                        className="rounded-full h-12 w-12 flexCenter mx-5 my-auto"
                        style={{
                          backgroundColor: "white",
                        }}
                      ></div>
                      <div className="details">
                        <div className="flex-grow ">
                          <p className="card-title capitalize">{e.name}</p>

                          <p className="card-subtitle card-subtitlex"></p>

                          <span className="box  shadow-md">
                            {e.description}{" "}
                          </span>

                          <p className="card-subtitle card-subtitlex"></p>
                        </div>
                      </div>
                    </div>
                    <div className="patient-icons"></div>
                  </div>
                );
              })}
            </div>
          )}
        </Modal>
      </div>
    </>
  );
};

export default Forms;
