import { ReactComponent as Plus } from "../../assets/illusts/Classic.svg";
import { ReactComponent as OneForm } from "../../assets/illusts/Simulation.svg";
import { ReactComponent as Close } from "../../assets/icons/icon-direction-gauche.svg";
import { useEffect, useState } from "react";
import Input from "../../components/FloatInput";
import Button from "../../components/ButtonUI";
import history from "../../history";
import { useAtom } from "jotai";
import { formAtom } from "../../state/formAtom";


type Props = any;
const CreationSteps: React.FC<Props> = ({ props }) => {
  const [step, setStep] = useState(0);
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [form, setForm] = useAtom(formAtom);

  useEffect(() => {
    let obj = {
      name: name,
      description: desc,
      externelResources: {},
      sections: [{ task_data: [], stepNbr: 0, sectionName: "Section 1" }],
    };
    setForm(obj);
  }, [name, desc]);

  const [selectedForm, setSelectedForm] = useState() as any;

  return (
    <>
      {type !== "" && step == 1 && (
        <>
          {" "}
          <h3 className="font-bold text-2xl text-black my-2 ">
            Attribuez un nom et une description significatifs à votre modèle de
            formulaire
          </h3>
          <Button
            status="icon-secondary"
            className="absolute z-40 inset-2 "
            onClick={() => {
              setStep(0);
              setType("");
            }}
          >
            <Close />
          </Button>
          <div className=" w-96  my-5 mx-4 items-center  ">
            <Input
              id="name"
              placeholder="Nom du modéle "
              labelText="Nom du modéle "
              type="text"
              onBlur={(e) => setName(e.target.value)}
              onChange={(e) => setName(e.target.value)}
              inlineLabel={false}
              onfocus={true}
              style={{ maxHeight: "50px" }}
            />
          </div>
          <div className=" w-96   mx-4 items-center  ">
            <Input
              id="desc"
              placeholder="Description du modéle"
              labelText="Description du modéle"
              type="text"
              style={{ maxHeight: "50px" }}
              onBlur={(e) => setDesc(e.target.value)}
              onChange={(e) => setDesc(e.target.value)}
              inlineLabel={false}
              onfocus={true}
            />
          </div>
          <Button
            disabled={name === ""}
            status="primary"
            className="px-6 py-2"
            onClick={() => history.push(`/creation`)}
          >
            Terminer
          </Button>
        </>
      )}

      {step == 0 && (
        <>
          {" "}
          <h3 className="font-bold text-2xl text-black my-2 ">
            Sélectionnez la mise en page de votre formulaire
          </h3>
          <h3>Choisissez une mise en page selon vos besoins</h3>{" "}
          <div className=" flex   justify-center  ">
            <div className="w-1/2  mx-6 py-6">
              <a
                className="c-card block bg-white shadow-md hover:shadow-2xl rounded-2xl overflow-hidden cursor-pointer"
                onClick={() => {
                  setType("Classic");
                  setStep(step + 1);
                }}
              >
                <div className="relative bg-blue-100    overflow-hidden flexCenter">
                  <Plus className="my-4 " />{" "}
                </div>
                <div className="px-4 py-2 min-h-full">
                  <h2 className="mt-2 mb-2  font-bold">Formulaire classique</h2>
                  <p className="text-md">
                    Afficher toutes les questions sur une ou plusieurs pages .
                  </p>
                </div>
              </a>
            </div>

            <div className="w-1/2 mx-6 py-6">
              <a
                onClick={() => {
                  setType("Simulation");
                  setStep(step + 1);
                }}
                className="c-card block bg-white shadow-md hover:shadow-2xl rounded-2xl overflow-hidden cursor-pointer"
              >
                <div className="relative  bg-blue-100  overflow-hidden flexCenter">
                  <OneForm className="my-4 " />{" "}
                </div>
                <div className="px-4 py-2 min-h-full">
                  <h2 className="mt-2 mb-2  font-bold">Formulaire à cartes</h2>
                  <p className="text-md">
                    Afficher une seule question par page .
                  </p>
                </div>
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CreationSteps;
