import { Input, Col, Row } from "antd";
import { useEffect, useState } from "react";
interface fullName {
  firstName: String;
  lastName: String;
}
export const FullNameInput = (props: any) => {
  console.log("papa!", props);
  const { dropEffect, type, id, label, field_name, rules, ...data } =
    props.element;
  const [fullName, setFullName] = useState<fullName>({
    lastName: "",
    firstName: "",
  });
  useEffect(() => {
    props.onChange(fullName);
  }, [fullName]);

  return (
    <>
      <Row>
        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
          {" "}
          <Input
            placeholder="Nom  "
            className="border rounded-lg h-10  "
            name="lastName"
            key="lastName"
            onChange={(e) => {
              fullName &&
                setFullName({
                  lastName: e.target.value,
                  firstName: fullName.firstName,
                });
            }}
            disabled={props.readOnly ? true : false}
          />
        </Col>
        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
          <Input
            placeholder="Prénom  "
            className="border rounded-lg h-10 mx-2"
            name="firstName"
            key="firstName"
            disabled={props.readOnly ? true : false}
            onChange={(e) => {
              fullName &&
                setFullName({
                  lastName: fullName.lastName,
                  firstName: e.target.value,
                });
            }}
          />
        </Col>
      </Row>
    </>
  );
};
