import "./styles-layout-doctor.scss";

const LayoutResponsable = ({ children }) => {
  return (
    <div className="layout-doctor flex">
      <div className="w-full pl-4"> {children} </div>
    </div>
  );
};

export default LayoutResponsable;
