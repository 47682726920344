import { Redirect, RouteProps } from "react-router";
import DisplayFormPage from "../Pages/DisplayForm";
import Dragable from "../Pages/DragableInterface";
import Forms from "../Pages/Forms";




const medecinResponsableRoutes: RouteProps[] = [

  {
    path: "/creation",
    component: Dragable,
    exact: true,
  },
  {
    path: "/",
    component: Forms,
    exact: true,
  },
  {
    path: "/Display",
    component: DisplayFormPage,
    exact: true,
  },

  

  { 
    render: () => <Redirect to="/" /> },
];


export default medecinResponsableRoutes;
