// ---- TYPES

enum Operator {
  EQ = "equal",
  NEQ = "not_equal",
  EQ_SUP = "equal_or_superior",
  EQ_INF = "equal_or_inferior",
  SUP = "superior",
  INF = "inferior",
}
function evaluate(c: any, values: any[]): boolean {
  const { filedTocompare, ifCondition, value } = c;
  const entires = Object.keys(values)
    .map(function (key) {
      return { key: key, value: values[key] };
    });
  const answer = entires.find(element => element.key === filedTocompare)
  if (!answer) return false;
  switch (ifCondition) {
    case Operator.EQ:
      return answer.value === value;
    case Operator.NEQ:
      return answer.value !== value;
    case Operator.EQ_SUP:
      return Number(answer.value) >= Number(value);
    case Operator.EQ_INF:
      return Number(answer.value) <= Number(value);
    case Operator.SUP:
      return Number(answer.value) > Number(value);
    case Operator.INF:
      return Number(answer.value) < Number(value);

    default:
      return false;
  }
}
export const resolver = (conditionalStatment: any, values) => {
  const { status, conditions, operator, action } = conditionalStatment
  let shouldShow = true
  let temp: boolean[] = []
  
  if (status) {



    conditions.map((c) => {
      temp.push(evaluate(c, values))
    })
    
    if (operator === "all") {
      temp.map((t) => {
        if (t == false) {
          shouldShow = false
        }
      })

    } else if (operator === "one") {
      let b = false
    
      temp.map((t) => {

       
        if (t == true) {
          b = true
        }
      })
      b ? shouldShow = true : shouldShow = false
    }

  }
  switch (action) {
    case 'S':
      return shouldShow
    case 'H':
      return !shouldShow
    default: return shouldShow

  }
}
function check(elm, elmId) {
  return elm.id !== elmId && !elm.content;
}
export const filterInputs = (elements: any[], elId: string, op: any, conditions: any) => {

  let filterdInputs: any[] = []
  elements.map((e) => {
    if (e.id !== elId && !e.content && e.type !== "Image")
      filterdInputs.push(e)
  })

  return filterdInputs
}