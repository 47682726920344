import React, { useState, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Row, Col, Tabs, Form, Modal, Layout, Descriptions } from "antd";
import Toolbar from "./toolbar";
import FormRenderer from "../form-renderer";
import FormViewer from "../form-viewer";
import PropTypes from "prop-types";
import { useAtom } from "jotai";
import { formAtom } from "../../../state/formAtom";
import { stepAtom } from "../../../state/currentStep";
import DropZone from "./drop-zone";
import { useIntl } from "react-intl";
import Button from "../../ButtonUI";

const { Sider, Content } = Layout;
const { TabPane } = Tabs;
const FormBuilder = ({
  fields,
  onUpdate,
  fieldProps,
  formProps,
  showPreviewTab,
  formSetup,
  ...props
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const [formValue, setFormValue] = useState([]) as any[];
  const [form] = Form.useForm();
  const [stAtom, setStAtom] = useAtom(stepAtom);
  const [currentIndex, setCurrentIndex] = useState(0);
  const intl = useIntl();
  const [formConfig, setFormConfig] = useAtom(formAtom);

  useEffect(() => {
    setFormConfig(formSetup);
  }, [formSetup]);

  const updateFormElement = (elem: any) => {
    let newSection = formConfig.sections;
    newSection[currentIndex].task_data = [...elem];

    setFormConfig({ ...formConfig, sections: newSection });

    onUpdate?.({ ...formConfig, sections: newSection });
  };
  const onFormSubmit = (values) => {
    setFormValue(values);
    setShowPreview(true);
  };
  const addStep = () => {
    let formConfigx = {
      task_data: [],
      stepNbr: formConfig.sections.length,
      sectionName:
        "Section " +
        (formConfig.sections.length ? formConfig.sections.length + 1 : "0"),
    };
    let steps = formConfig.sections ? formConfig.sections : [];
    let data = {
      name: formConfig.name || "",
      description: formConfig.description || "",
      sections: [...steps, formConfigx],
    };
    setFormConfig(data);
  };
  useEffect(() => {
    console.log("formValue", formValue);
  }, [formValue]);

  const comb = () => {
    let image: any[] = [];
    formConfig.sections.map((step) => {
      image.concat(step);
    });

    return image;
  };

  function ControlledTabs() {
    return (
      <>
        <Tabs
          onChange={(e) => {
            setStAtom(parseInt(e));
            setCurrentIndex(parseInt(e));
          }}
        >
          {formConfig.sections.map((step, i) => (
            <TabPane tab={step.sectionName} key={i}>
              <Content
                style={{
                  padding: 24,
                  margin: 0,
                  minHeight: 280,
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  boxShadow: "0px 10px 26px 0px gray",
                }}
              >
                <DropZone
                  key={i}
                  elements={step.task_data}
                  onUpdate={updateFormElement}
                  formProps={formProps}
                  formConfig={formConfig}
                  stepNbr={currentIndex}
                />
              </Content>
            </TabPane>
          ))}
        </Tabs>
      </>
    );
  }
  function loadLocaleData(locale: string) {
    switch (locale) {
      case "fr":
        return import("../language-provider/locales/fr-fr.json");
      default:
        return import("../language-provider/locales/en-us.json");
    }
  }
  const messages = loadLocaleData("fr");

  return (
    <DndProvider backend={HTML5Backend}>
      {showPreviewTab ? (
        <>
          <Tabs defaultActiveKey="1" centered>
            <TabPane tab={intl.formatMessage({ id: "editorText" })} key="1">
              <Layout
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "stretch",
                  gap: "10px",
                  borderRadius: "10px",
                }}
              >
                <Sider
                  theme="light"
                  width={250}
                  breakpoint="md"
                  className="rounded-lg p-2 bg-transparent"
                  collapsedWidth={0}
                >
                  <div className="bg-gray-200 p-1 py-4 rounded-lg ">
                    <Button
                      status="primary"
                      className="p-2 mx-10 my-2 "
                      onClick={() => addStep()}
                    >
                      {intl.formatMessage({ id: "addStepText" })}
                    </Button>
                    <Toolbar fieldProps={fieldProps} fields={undefined} />
                  </div>
                </Sider>

                {ControlledTabs()}
              </Layout>
            </TabPane>
            <TabPane tab={intl.formatMessage({ id: "previewText" })} key="2">
              <Row>
                <Col span={2}></Col>
                <Col span={20}>
                  <Form
                    onValuesChange={(e) => {
                      setFormValue(form.getFieldsValue());
                    }}
                    form={form}
                    {...formProps}
                    onFinish={onFormSubmit}
                    onSubmit
                  >
                    {formConfig.sections.map((step, i) => {
                      return (
                        <div>
                          <h2>{step.sectionName}</h2>
                          <FormRenderer
                            key={i}
                            elements={step.task_data}
                            values={formValue}
                            form={undefined}
                          ></FormRenderer>
                        </div>
                      );
                    })}
                    <Form.Item>
                      <Button status="primary" className="p-2 my-1 ">
                        {intl.formatMessage({ id: "gSubmit" })}
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={2}></Col>
              </Row>
            </TabPane>
          </Tabs>
          <Modal
            title="View"
            footer={null}
            width={"90%"}
            visible={showPreview}
            onCancel={(e) => setShowPreview(false)}
            style={{ width: "100%", resize: "unset" }}
          >
            <FormViewer
              elements={formConfig.sections[0].task_data}
              values={formValue}
              descriptionProps={undefined}
              descriptionItemProps={undefined}
            ></FormViewer>
          </Modal>
        </>
      ) : (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "stretch",
            gap: "10px",
          }}
        >
          <Sider theme="light" width={250} breakpoint="md" collapsedWidth={0}>
            <Toolbar
              locale="fr"
              messages={messages}
              fieldProps={fieldProps}
              fields={undefined}
            />
          </Sider>
        </div>
      )}
    </DndProvider>
  );
};

FormBuilder.defaultProps = {
  fields: [],
  showPreviewTab: true,
  formProps: {
    labelAlign: "left",
    colon: true,
    requiredMark: true,
    labelCol: { span: 5 },
  },
};
FormBuilder.propTypes = {
  fields: PropTypes.array,
  onUpdate: PropTypes.func,
  fieldProps: PropTypes.array,
  formProps: PropTypes.object,
  showPreviewTab: PropTypes.bool,
};

export default FormBuilder;
