import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "./wyswyg.css";
const FComp = ({ value, onChange, width, height, ...props }) => {
  const [editorState, setEditorState] = useState("<p></p>") as any;
  ////console.log("val",value)
  const  [editorS, setEditorS] = useState(null)as any 
  useEffect(() => {
    const contentBlocks = convertFromHTML(value);
  
    const contentState = ContentState.createFromBlockArray(
      contentBlocks.contentBlocks,
      contentBlocks.entityMap
    );
  
    setEditorS(EditorState.createWithContent(contentState));
  }, []);

  const onEditorStateChange = async (newState) => {
    setEditorState(newState);
    setEditorS(newState)


    const html = draftToHtml(convertToRaw(newState.getCurrentContent()))
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "")
      .replace(/&nbsp;/g, " ")
      .replace(/(?:\r\n|\r|\n)/g, " ");
    onChange?.(html);

  
  };
  return (
    <Editor
      editorState={editorS}
      wrapperClassName="react-wysiwyg-typescript-wrapper"
      editorClassName="react-wysiwyg-typescript-editor"
      toolbarClassName="react-wysiwyg-typescript-toolbar"
      onEditorStateChange={onEditorStateChange}
      placeholder="Write your content ..."
      editorStyle={{ height: "130px", padding: "8px" }}
      
    />
  );
};
export default FComp;
