
import { Form, Space } from "antd";
import { useIntl } from "react-intl";
import { renderField } from "./renderfields";

const Rules = (props) => {
  const intl = useIntl()
  return (
    <Form.List name={props.name}>
      {(fields) => (
        <Form.Item label="Validations">
          {fields.map((field, index) => (
            <Space key={field.key} align="baseline">
              {Object.keys(props.value[index]).map((key) => {
                return renderField({
                  name: [field.name, key],
                  value: props.value[index][key],
                  intl:intl
                });
              })}
            </Space>
          ))}
        </Form.Item>
      )}
    </Form.List>
  );
};

export default Rules;
