
import { FormElement } from "../../elements";
import Options from "./options";
import Rules from "./rules";
import ConditionLogic from "./conditionLogic";
import { useIntl } from "react-intl";


const camel2title = (camelCase) => {
  if (Array.isArray(camelCase)) camelCase = camelCase[camelCase.length - 1];
  if (typeof camelCase === "string")
    return camelCase
      .replace(/([A-Z])/g, (match) => ` ${match}`)
      .replace(/^./, (match) => match.toUpperCase())
      .trim();
  else return camelCase;
};
interface Props {
  name: any;
  value: any;
  form?: any;
  elements?: any;
  selectedElm?: any;
  intl?:any;
}
const renderField = ({ name, value, form, elements, selectedElm,intl }: Props) => {
  let type = "TextArea";
  let options: any = [];

  switch (typeof value) {
    case "boolean":
      type = "RadioButtons";
      options = [
        { label: intl.formatMessage({id:"Yes"}), value: true },
        { label: intl.formatMessage({id:"No"}), value: false },
      ];
      return (
        <FormElement
          key={name}
          element={{
            field_name: name,
            options: options,
            label: intl.formatMessage({id:name}),
            type: type,
          }}
          readOnly={false}
          form={form}
        />
      );
    case "string":
      if ((name === "content" ) ) type = "Editor";
      else type = "TextInput";
      return (
        <FormElement
          element={{
            field_name: name,
            options: options,
            label:intl.formatMessage({id:name}) ,
            type: type,
          }}
          readOnly={false}
          form={form}
        />
      );
    case "number":
      type = "NumberInput";
      return (
        <FormElement
          key={name}
          element={{
            field_name: name,
            options: options,
            label: camel2title(name),
            type: type,
          }}
          readOnly={false}
          form={form}
        />
      );
    case "object":
      if (name === "options") {
        return <Options key={name} name={["options"]}></Options>;
      }
      if (name === "rules") {
        return <Rules key={name} name={["rules"]} value={value}></Rules>;
      }

      if (name === "conditionLogic")
        return (
          <ConditionLogic
            selectedElm={selectedElm}
            elements={elements}
            key={name}
            name={["conditionLogic"]}
            value={value}
            form={form}
          />
        );
      break;
    default:
      type = "TextArea";
      return (
        <FormElement
          key={name}
          element={{
            field_name: name,
            options: options,
            label: camel2title(name),
            type: type,
          }}
          readOnly={undefined}
          form={form}
        />
      );
  }
};

export { renderField };
