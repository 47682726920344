
import { Button, Form, Input, Space } from "antd";


const Options = (props) => {


  return (
    <Form.List name={props.name}>
      {(fields, { add, remove }) => (
        <Form.Item label="Options">
          {fields.map((field) => (
            <Space key={field.key} align="baseline">
              <Form.Item {...field} name={[field.name, "label"]}>
                <Input className="rounded-lg" placeholder="Label" />
              </Form.Item>
              <Form.Item {...field} name={[field.name, "value"]}>
                <Input placeholder="Value" className="rounded-lg" />
              </Form.Item>
              <i
                className="fa fa-minus-circle"
                onClick={() => remove(field.name)}
              />
            </Space>
          ))}

          <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                add();
                
              }}
              block
              color=""
              className="rounded-lg bg-blue-600"
            >
              Add Option
            </Button>
          </Form.Item>
        </Form.Item>
      )}
    </Form.List>
  );
};

export default Options;
