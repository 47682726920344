import React from "react";
import { Divider, Tag, Image, Rate, Slider } from "antd";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
const ViewElement = ({ element, value, ...props }) => {
  const { type, ...data } = element;

  const renderElement = () => {
    switch (type) {
      case "Header":
        return <h1>{ReactHtmlParser(data.content)}</h1>;
      case "Paragraph":
        return <p>{ReactHtmlParser(data.content)}</p>;
      case "LineBreak": {
        const { content, ...props } = data;
        return <Divider {...props}>{ReactHtmlParser(data.content)}</Divider>;
      }
      case "Image": {
        if (data.src === "")
          data.src =
            "https://via.placeholder.com/728x90.png?text=Place+Your+Image+Here";
        return <img src={data.src} alt={data.alt} />;
      }
      case "TextInput":
      case "Email":
      case "NumberInput":
      case "Dropdown":
      case "RadioButtons":
        return <div>{value}</div>;
      case "File":
        console.log("file ==>", value);
        return (
          <>
            {value?.fileList.map((item, index) => (
              <div key={index}>
                <a href={item.url} target="_blank" rel="noreferrer">
                  {item.name}
                </a>
              </div>
            ))}
          </>
        );
      case "Photo":
        console.log("Photo ==>", value);
        return (
          <Image.PreviewGroup>
            {value?.fileList.map((item, index) => (
              <Image
                width={200}
                src={item.thumbUrl}
                key={index}
                style={{
                  padding: "5px",
                  minHeight: "100px",
                  border: "1px solid #ccc",
                }}
              />
            ))}
          </Image.PreviewGroup>
        );
      case "Rating":
        return <Rate disabled value={value}></Rate>;
      case "Signature":
        return <Image width={200} src={value} />;
      case "Range":
        const marks = { [data.min]: data.minLabel, [data.max]: data.maxLabel };
        const { minLabel, maxLabel, ...rangeProps } = data;
        return (
          <Slider
            value={value}
            marks={marks}
            onChange={(e) => {}}
            {...rangeProps}
          ></Slider>
        );
      case "Checkboxes":
        return <div>{value?.join(", ")}</div>;
      case "Tags":
        return (
          <div>
            {value?.map((v, index) => (
              <Tag key={index}>{v}</Tag>
            ))}
          </div>
        );
      case "DatePicker":
      case "TimePicker":
        return <div>{value ? moment(value).format(data.format) : null}</div>;

      case "TextArea":
        return <pre>{value}</pre>;
      case "fullName":
        return <div>{value.lastName + " " + value.firstName}</div>;
      case "fullAdress":
        return (
          <div>
            {value.streetAddress +
              " ," +
              value.city +
              " " +
              value.zipCode +
              " , " +
              value.country}
          </div>
        );

      default:
        throw new Error(`Unknown element type: ${type}`);
    }
  };
  return renderElement();
};

export default ViewElement;
