

const FormItems = (intl) => {


    return [
        {
            key: 'Header',
            name: intl.formatMessage({ id: "header-text" }),
            icon: 'fas fa-heading',
            fieldProps: {
                content: intl.formatMessage({ id: "header-content" }),
                conditionLogic: {
                    status: false,
                    action: 'S', operator: "all", conditions: [
                        { filedTocompare: "", ifCondition: "", value: "" }
                    ]
                },
            }
        },
        {
            key: 'Paragraph',
            name: intl.formatMessage({ id: "paragraph" }),
            icon: 'fas fa-paragraph',
            fieldProps: {
                content: intl.formatMessage({ id: "paragraph-content" }),
                conditionLogic: {
                    status: false,
                    action: 'S', operator: 'all', conditions: [
                        { filedTocompare: "", ifCondition: "", value: "" }
                    ]
                },
            }
        },
        {
            key: 'LineBreak',
            name: intl.formatMessage({ id: "line-break" }),
            icon: 'fas fa-arrows-alt-h',
            fieldProps: {
                content: '----', conditionLogic: {
                    status: false,
                    action: 'S', operator: 'all', conditions: [
                        { filedTocompare: "", ifCondition: "", value: "" }
                    ]
                },
                plain: false,
                dashed: false
            }

        },
        {
            key: 'Image',
            name: intl.formatMessage({ id: "image" }),
            icon: 'far fa-image',
            fieldProps: {
                field_name: 'image_', conditionLogic: {
                    status: false,
                    action: 'S', operator: 'all', conditions: [
                        { filedTocompare: "", ifCondition: "", value: "" }
                    ]
                },
                src: '',
                alt: 'Update URL'
            }
        },


        {
            key: 'TextInput',
            name: intl.formatMessage({ id: "text-input" }),
            icon: 'fas fa-font',
            fieldProps: {
                field_name: 'text_input_',
                label: 'Label',
                placeHolder: '',

                rules: [
                    { required: false, message: intl.formatMessage({ id: "validation-msg-Req" }) }
                ],
                conditionLogic: {
                    status: false,
                    action: 'S', operator: 'all', conditions: [
                        { filedTocompare: "", ifCondition: "", value: "" }
                    ]
                },
            }
        },

        {
            key: 'NumberInput',
            name: intl.formatMessage({ id: "number-input" }),
            icon: 'fas fa-plus',

            fieldProps: {
                field_name: 'number_input_',
                label: 'Label',
                conditionLogic: {
                    status: false,
                    action: 'S', operator: 'all', conditions: [
                        { filedTocompare: "", ifCondition: "", value: "" }
                    ]
                },
                rules: [
                    { required: false, message: intl.formatMessage({ id: "validation-msg-Req" }) },


                ]
            }
        },
        {
            key: 'TextArea',
            name: intl.formatMessage({ id: "TextArea" }),
            icon: 'fas fa-text-height',
            fieldProps: {
                field_name: 'text_area_',
                label: 'Label',
                conditionLogic: {
                    status: false,
                    action: 'S', operator: 'all', conditions: [
                        { filedTocompare: "", ifCondition: "", value: "" }
                    ]
                },
                rules: [
                    { required: false, message: intl.formatMessage({ id: "validation-msg-Req" }) }
                ]
            }
        },
        {
            key: 'Dropdown',
            name: intl.formatMessage({ id: "dropdown" }),
            icon: 'far fa-caret-square-down',
            fieldProps: {
                conditionLogic: {
                    status: false,
                    action: 'S', operator: 'all', conditions: [
                        { filedTocompare: "", ifCondition: "", value: "" }
                    ]
                },
                field_name: 'dropdown_',
                label: 'Select',
                options: [
                    { label: 'Option 1', value: 'Option 1' },
                    { label: 'Option 2', value: 'Option 2' },
                    { label: 'Option 3', value: 'Option 3' },
                ],
                rules: [
                    { required: false, message: intl.formatMessage({ id: "validation-msg-Req" }) }
                ]
            }

        },
        {
            key: 'Tags',
            name: intl.formatMessage({ id: "tags" }),
            icon: 'fas fa-tags',
            fieldProps: {
                field_name: 'tags_',
                label: 'Tag',
                options: [
                    { label: 'Option 1', value: 'Option 1' },
                    { label: 'Option 2', value: 'Option 2' },
                    { label: 'Option 3', value: 'Option 3' },
                ],
                conditionLogic: {
                    status: false,
                    action: 'S', operator: 'all', conditions: [
                        { filedTocompare: "", ifCondition: "", value: "" }
                    ]
                },
                rules: [
                    { required: false, message: intl.formatMessage({ id: "validation-msg-Req" }) }
                ]
            }
        },
        {
            key: 'Checkboxes',
            name: intl.formatMessage({ id: "checkboxes" }),
            icon: 'far fa-check-square',
            fieldProps: {
                field_name: 'checkboxes_',
                label: 'Checkbox',
                conditionLogic: {
                    status: false,
                    action: 'S', operator: 'all', conditions: [
                        { filedTocompare: "", ifCondition: "", value: "" }
                    ]
                },
                options: [
                    { label: 'Option 1', value: 'Option 1' },
                    { label: 'Option 2', value: 'Option 2' },
                    { label: 'Option 3', value: 'Option 3' },
                ],
                rules: [
                    { required: false, message: intl.formatMessage({ id: "validation-msg-Req" }) }
                ]
            }
        },
        {
            key: 'RadioButtons',
            name: intl.formatMessage({ id: "RadioButtons" }),
            icon: 'far fa-dot-circle',

            fieldProps: {
                field_name: 'radiobuttons_',
                label: 'Options',
                conditionLogic: {
                    status: false,
                    action: 'S', operator: 'all', conditions: [
                        { filedTocompare: "", ifCondition: "", value: "" }
                    ]
                },
                options: [
                    { label: 'Option 1', value: 'Option 1' },
                    { label: 'Option 2', value: 'Option 2' },
                    { label: 'Option 3', value: 'Option 3' },
                ],
                rules: [
                    { required: false, message: intl.formatMessage({ id: "validation-msg-Req" }) }
                ]
            }
        },


        {
            key: 'Photo',
            name: intl.formatMessage({ id: "photo" }),
            icon: 'fa fa-camera',
            fieldProps: {
                field_name: 'photo_',
                conditionLogic: {
                    status: false,
                    action: 'S', operator: 'all', conditions: [
                        { filedTocompare: "", ifCondition: "", value: "" }
                    ]
                },
                label: 'Photo',
                multiple: true,
                accept: 'image/png, image/jpeg',
                rules: [
                    { required: false, message: intl.formatMessage({ id: "validation-msg-Req" }) }
                ]
            }
        },
        {
            key: 'File',
            name: intl.formatMessage({ id: "file-attachment" }),
            icon: 'fas fa-file',
            fieldProps: {
                field_name: 'file_',
                conditionLogic: {
                    status: false,
                    action: 'S', operator: 'all', conditions: [
                        { filedTocompare: "", ifCondition: "", value: "" }
                    ]
                },
                label: 'Attach File',
                static: true,
                maxCount: 5,
                multiple: true,
                rules: [
                    { required: false, message: intl.formatMessage({ id: "validation-msg-Req" }) }
                ]
            }
        }, {
            key: 'DatePicker',
            name: intl.formatMessage({ id: "date" }),
            icon: 'far fa-calendar-alt',
            fieldProps: {
                field_name: 'date_picker_',
                conditionLogic: {
                    status: false,
                    action: 'S', operator: 'all', conditions: [
                        { filedTocompare: "", ifCondition: "", value: "" }
                    ]
                },
                label: 'Date',
                allowClear: true,
                format: 'MM/DD/YYYY',
                rules: [
                    { required: false, message: intl.formatMessage({ id: "validation-msg-Req" }) }
                ]
            }
        },
        {
            key: 'TimePicker',
            name: intl.formatMessage({ id: "time" }),
            icon: 'far fa-clock',
            fieldProps: {
                field_name: 'time_picker_',
                conditionLogic: {
                    status: false,
                    action: 'S', operator: 'all', conditions: [
                        { filedTocompare: "", ifCondition: "", value: "" }
                    ]
                },
                label: intl.formatMessage({ id: "time" }),
                format: 'hh:mm a',
                allowClear: true,
                hourStep: 1,
                minuteStep: 1,
                rules: [
                    { required: false, message: intl.formatMessage({ id: "validation-msg-Req" }) }
                ]
            }
        },
        {
            key: 'Rating',
            name: intl.formatMessage({ id: "rating" }),
            icon: 'fas fa-star',
            fieldProps: {

                conditionLogic: {
                    status: false,
                    action: 'S', operator: 'all', conditions: [
                        { filedTocompare: "", ifCondition: "", value: "" }
                    ]
                },
                field_name: 'rating_',

                label: 'Rate',
                allowClear: true,
                allowHalf: false,
                count: 5,
                rules: [
                    { required: false, message: intl.formatMessage({ id: "validation-msg-Req" }) }
                ]
            }
        },
        {
            key: 'Signature',
            name: intl.formatMessage({ id: "signature" }),
            icon: 'fas fa-pen-square',
            fieldProps: {
                field_name: 'signature_',
                conditionLogic: {
                    status: false,
                    action: 'S', operator: 'all', conditions: [
                        { filedTocompare: "", ifCondition: "", value: "" }
                    ]
                },
                label: 'Signature',
                rules: [
                    { required: false, message: intl.formatMessage({ id: "validation-msg-Req" }) }
                ]
            }
        },


        {
            key: 'Range',
            name: intl.formatMessage({ id: "range" }),
            icon: 'fas fa-sliders-h',
            fieldProps: {
                conditionLogic: {
                    status: false,
                    action: 'S', operator: 'all', conditions: [
                        { filedTocompare: "", ifCondition: "", value: "" }
                    ]
                },
                field_name: 'range_',
                label: 'Label',
                step: 1,
                min: 1,
                max: 5,
                minLabel: intl.formatMessage({ id: "Easy" }),
                maxLabel: intl.formatMessage({ id: "Difficult" })
            }
        },

        // {
        //     key: 'TwoColumnRow',
        //     name: 'Two Column Row',
        //     icon: 'fas fa-columns',
        //     fieldProps: {
        //         field_name: 'two_col_row_',
        //         canHaveAnswer: false,
        //         label: '',
        //     }
        // },
        // {
        //     key: 'ThreeColumnRow',
        //     name: 'Three Column Row',
        //     icon: 'fas fa-columns',
        //     fieldProps: {
        //         field_name: 'three_col_row_',
        //         canHaveAnswer: false,
        //         label: '',
        //     }
        // },
        // {
        //     key: 'FourColumnRow',
        //     name: 'Four Column Row',
        //     icon: 'fas fa-columns',
        //     fieldProps: {
        //         field_name: 'four_col_row_',
        //         canHaveAnswer: false,
        //         label: '',
        //     }
        // }
    ]
};

export default FormItems;