import PropTypes from "prop-types";
import React from "react";
import { ReactSVG } from "react-svg";
import styled, { css } from "styled-components";

type Props = React.DetailedHTMLProps<
  React.SVGAttributes<HTMLOrSVGElement>,
  HTMLOrSVGElement
> & { isIcon?: boolean; groundColor?: string; position?: string };

const Icon: React.FC<Props> = ({
  color,
  width,
  height,
  transform,
  name,
  isIcon,
  groundColor,
  position,
  ...props
}) => {
  
  return (
 <></>
  );
};

Icon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  position: PropTypes.string,
  transform: PropTypes.string,
  isIcon: PropTypes.bool.isRequired,
  groundColor: PropTypes.string,
};

export default Icon;
