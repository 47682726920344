import { useEffect, useState } from "react";
import { Drawer, Form, Space, Grid } from "antd";
import { renderField } from "./renderfields";
import { isMobile } from "../../Utils";
import Button from "../../../ButtonUI";
import { useIntl } from "react-intl";

const PropertyEditor = ({ element, show, onClose, onSubmit, elements }) => {
  const { type, id, dropEffect, field_name, ...editableProps } = element || {
    type: null,
    id: null,
    dropEffecct: null,
    field_name: null,
  };
  const obj = { filedId: id, ...editableProps };
  console.log("obj=<", type);

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const { useBreakpoint } = Grid;
  const [selected, setSelected] = useState(id ? id : null);
  useEffect(() => {
    setSelected(id);
  }, [id]);
  const intl = useIntl();
  console.log(intl.messages);
  useEffect(() => {
    form.setFieldsValue(editableProps);
  }, [editableProps, form]);

  useEffect(() => {
    if (!visible) onClose?.();
  }, [visible, onClose]);

  useEffect(() => {
    if (show) setVisible(show);
  }, [show]);
  useEffect(() => {
    return () => {
      console.log("hahahaha");
    };
  }, []);

  const onFinish = (values) => {
    if (values.conditionLogic && values.conditionLogic.status) {
      console.log(" fil");
    } else {
      console.log("no fil");
    }
    onSubmit?.(values);
    setVisible(false);
  };

  return (
    <Drawer
      title={intl.formatMessage({ id: "editText" })}
      onClose={(e) => setVisible(false)}
      drawerStyle={{ borderRadius: "10px" }}
      visible={visible}
      width={isMobile(useBreakpoint()) ? "100%" : "736px"}
      footer={
        <Space>
          <div className="flex space-x-3 mt-2 text-xs font-semibold uppercase">
            <div className="flex-auto flex space-x-3 flex-row">
              <Button
                className="p-2 "
                onClick={(e) => {
                  form.submit();
                }}
              >
                {intl.formatMessage({ id: "submitButton" })}
              </Button>
              <Button
                className="p-2 "
                onClick={(e) => setVisible(false)}
                status="secondary"
              >
                {intl.formatMessage({ id: "cancelButton" })}
              </Button>
            </div>
          </div>
        </Space>
      }
    >
      <Form
        form={form}
        onFinish={onFinish}
        labelAlign="left"
        colon
        requiredMark
        layout="vertical"
        labelCol={{ span: 8 }}
      >
        {Object.keys(editableProps).map((name) => {
          console.log(editableProps["rules"]);
          const val =
            type === "Email" && name === "rules"
              ? editableProps[name].map((e, i) => i == 0 && e)
              : editableProps[name];
          console.log("valhala", val);
          return renderField({
            name,
            value: val,
            form,
            elements,
            selectedElm: selected,
            intl: intl,
          });
        })}
      </Form>
    </Drawer>
  );
};

export default PropertyEditor;
