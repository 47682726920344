import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { FormElement } from "../elements";
import { resolver } from "../conditionalLogic";
const FormRenderer = ({ elements, form, ...props }) => {
  useEffect(() => {
    
  }, [elements]);
const intl = useIntl()  
return (
    <div className="bg-white p-4 rounded-lg m-1 shadow-2xl">
      {elements.length > 0 ? (
        elements.map((element) => {
          return (
            resolver(element.conditionLogic, props.values) && (
              <FormElement
                form={form}
                key={element.id}
                element={element}
                readOnly={false}
              />
            )
          );
        })
      ) : (
        <span className="text-gray-400 flexCenter ">
   { intl.formatMessage({ id: "noItmesMsg" })}        </span>
      )}
    </div>
  );
};

export default FormRenderer;
