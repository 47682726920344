import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import history from "./history";
import "./theme.scss";

import Navigator from "./components/Navigator";
import { Router } from "react-router-dom";

const App: React.FC = () => {
  const [loading, setlLading] = useState(true);

  console.log(
    `%c[info] app running in %c${process.env.NODE_ENV} mode.`,
    null,
    "color: #3B82F6; font-weight: bold;"
  );

  return (
    <Router history={history}>
      <Navigator />
    </Router>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
