import { Route, Redirect, Switch, } from "react-router-dom";


import { UserRole } from "../../types/user";
import routes from "../../routes";
import LayoutResponsable from "../../layouts/LayoutResponsable";

const Navigator: React.FC = () => {
  const Routes = () => {
    return (
      <>
        <Switch>
          {routes().map((routeProps, index) => (
            <Route key={index} {...routeProps} />
          ))}
        </Switch>
      </>
    );
  };

  return <LayoutResponsable>{Routes()}</LayoutResponsable>;
};

export default Navigator;
