import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDrop } from "react-dnd";
import ItemTypes from "../ItemTypes";
import classes from "../css/drop-zone.module.css";
import SortableElement from "./sortable-element";
import { Form } from "antd";
import PropertyEditor from "./property-editor";
import { useIntl } from "react-intl";

export const DropZone = ({ elements, onUpdate, formProps, ...props }) => {
  const [visible, setVisible] = useState(false);
  const [currentElement, setCurrentElement] = useState(null) as any;
  const [currentIndex, setCurrentIndex] = useState(null);
  const ref = useRef();
  const intl = useIntl();
  const [{ canDrop, isOver, draggedItem }, drop] = useDrop(() => ({
    accept: ItemTypes.CARD,
    drop: (item, monitor) => item,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      draggedItem: monitor.getDropResult(),
    }),
  }));
  const editHandler = (element, index) => {
    setCurrentElement(element);
    setCurrentIndex(index);
    setVisible(true);
  };
  useEffect(() => {
    const fc = props.formConfig.sections[props.stepNbr].task_data;

    if (draggedItem && elements && onUpdate)
      if (draggedItem.id) {
        onUpdate([...fc, draggedItem]);
        editHandler(draggedItem, elements.length);
        setCurrentElement(draggedItem);
      }
  }, [isOver]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      let elems = [...elements];
      [elems[dragIndex], elems[hoverIndex]] = [
        elems[hoverIndex],
        elems[dragIndex],
      ];
      onUpdate(elems);
    },
    [elements, onUpdate]
  );

  const deleteHandler = (element, index) => {
    onUpdate?.(elements.filter((element, i) => index !== i));
  };

  const submitHandler = (values) => {
    const element = { ...currentElement, ...values };

    onUpdate?.(elements.map((e, i) => (currentIndex !== i ? e : element)));
  };

  const isActive = canDrop && isOver;
  let backgroundColor = "#fff";
  if (isActive) {
    backgroundColor = "#fafafa";
  } else if (canDrop) {
    backgroundColor = "#fafafa";
  }

  return (
    <div
      ref={drop}
      className={classes.dropzone}
      style={{ backgroundColor: backgroundColor }}
    >
      <Form
        labelAlign="right"
        colon
        requiredMark
        labelCol={{ span: 5 }}
        {...formProps}
      >
        {elements.map((element, index) => {
          return (
            <SortableElement
              key={element.id}
              index={index}
              element={element}
              onMove={moveCard}
              onEdit={editHandler}
              onDelete={deleteHandler}
              readOnly={true}
            />
          );
        })}
        {elements.length === 0 && (
          <div
            style={{
              border: "1px dashed #e9e9e9",
              padding: "10px",
              margin: "10px",
              textAlign: "center",
              fontSize: "1.5em",
              fontWeight: "bold",
              backgroundColor: "#fafafa",
            }}
          >
            {intl.formatMessage({ id: "dndMsg" })}
          </div>
        )}
      </Form>
      <PropertyEditor
        show={visible}
        element={currentElement}
        onClose={(e) => setVisible(false)}
        onSubmit={submitHandler}
        elements={elements}
      ></PropertyEditor>
    </div>
  );
};

export default DropZone;
