const isMobile = (breakPoints) => {
    return (breakPoints.xs || (breakPoints.sm && !breakPoints.md))
}
const checkIfMultiple = (
    currentCondition: Partial<any>
  ): boolean => {
    const multipleInput: string[] = ["Checkboxes", "RadioButtons", "Tags","Dropdown"];
  
    const type =
      currentCondition?.target?.type !== undefined
        ? currentCondition?.target?.type
        : "text_area";
    if (multipleInput.includes(type)) {
      return true;
    } else return false;
  };
  

export { isMobile,checkIfMultiple };