interface Props {
  classNames?: string;
}
const index: React.FC<Props> = ({ classNames }) => {
  return (
    <div className={`${classNames} h-screen flexCenter `}>
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
    </div>
  );
};

export default index;
